<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0"
          style="height: calc(100vh - 130px); overflow: auto;">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      two-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="mt-0"
                          style="border: 2px solid #ffffff;">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text"
                            style="opacity: 70%;">
                            {{ item.email !== '' ? item.email : '-' }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="body-2">
                            Telp. {{ item.mobilephone !== '' ? item.mobilephone : '-' }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Pelanggan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ detail.name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <v-avatar
                tile
                size="150">
                <v-img
                  width="100%"
                  height="100%"
                  style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                        border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="bidanvitacare"
                  :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-card-text>

            <v-card-text>
              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Nama Lengkap
                </div>

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.name }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tipe
                </div>

                <div
                  class="body-2 black--text">
                  {{
                    detail.type === 'ibu_hamil' ? 'Ibu Hamil' :
                    detail.type === 'ibu_menyusui' ? 'Ibu Menyusui' :
                    detail.type === 'bayi' ? 'bayi' :
                    detail.type === 'anak' ? 'Anak' : 'Umum'
                  }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Email
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.email !== '' ? detail.email : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Instagram
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.instagram !== '' ? detail.instagram : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  No. Handphone
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.mobilephone !== '' ? detail.mobilephone : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  No. Handphone Lainnya
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.other_mobilephone !== '' ? detail.other_mobilephone : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Jenis Kelamin
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.gender === 'male' ? 'Laki-laki' : 'Perempuan' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tempat Lahir
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.birth_place !== '' ? detail.birth_place : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tanggal Lahir
                </div>

                <div
                  class="body-2 black--text">
                  <span v-if="detail.birth_date !== ''">
                    {{ detail.birth_date | date }}
                  </span>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Umur
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.age !== '' && detail.age !== null ? detail.age : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Penyakit Bawaan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.congenital_disease !== '' ? detail.congenital_disease : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Nama Wali / Penanggung Jawab
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.wali_name !== '' ? detail.wali_name : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Status Wali / Penanggung Jawab
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.wali_status !== '' ? detail.wali_status : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Catatan Khusus
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.notes.length > 0 ? detail.notes[0] : '-' }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: {
          detail: false
        },
        search: '',
        detail: {},
        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'role[neq]': 'superadmin'
        }

        await this.$axios.$get(`${process.env.API}therapist/customer`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/customer/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      }
    }
  }
</script>

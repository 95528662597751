<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.referensi"
          persistent>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Nomor Referensi
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-4">
              <v-text-field
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Contoh. 1234567890"

                hide-details

                v-model.number="list.payment[selected.menu].data.method_ref">
              </v-text-field>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.bank"
          persistent
          scrollable>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Bank
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <v-row>
                <v-col
                  cols="12"
                  class="py-0">
                  <v-list
                    v-for="(item, index) in list.bank"
                    :key="index"
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius py-0">
                    <v-list-item
                      @click="list.payment[selected.menu].data.method_bank_name = item.text"
                      class="px-0">
                      <v-list-item-avatar
                        size="40"
                        tile>
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.icon !== '' ? item.icon : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action v-if="list.payment[selected.menu].data.method_bank_name === item.text">
                        <v-icon
                          color="green"
                          size="18">
                          mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.info"
          scrollable>
          <v-card v-if="Object.keys(form).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Info Order
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <v-row>
                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Tipe
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.type === 'on_site' ? 'On Site' : 'Home Care' }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Jml Item
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.items.length }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Alamat
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ Object.keys(selected.address).length > 0 ? selected.address.address_full : '-' }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Transport
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.transport_qty }} x {{ form.transport_cost | price }} = {{ form.transport_total_cost | price }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0">
                  Diskon
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <span v-if="form.discount_percent > 0">
                    {{ form.discount_percent }}%
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.admin"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Admin
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetchAdmin();"
                v-on:keyup.enter="fetchAdmin()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data admin ?"
                clearable
                class="mb-2">
              </v-text-field>

              <div
                class="body-2 font-italic orange--text mt-4">
                *Jika tidak memilih admin / kasir, maka otomatis data Anda sebagai kasir yang mencetak nota.
              </div>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list.user_admin"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.user_admin.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.user_admin.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.cashier_id === item.id ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="form.cashier_id = item.id; form.cashier_name = item.name;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.role }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-8"
                size="125"
                message="Admin" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <div v-if="message.error.length > 0"
              class="text-center mx-4 d-flex align-center">
              <div
                class="error--text">
                <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                <span
                  class="ml-1">
                  {{ message.error }}
                </span>
              </div>
            </div>

            <v-card-actions>

              <v-spacer/>

              <v-btn
                @click="save()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.admin = false; form.cashier_id = ''; form.cashier_name = ''; message.error = '';"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <ValidationObserver
            ref="form">
            <v-form>
              <v-card
                flat
                color="#FFFFFF60"
                style="border-radius: 0px;">
                <v-list
                  flat
                  dense
                  color="transparent"
                  class="pb-0">
                  <v-list-item>
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        <!-- {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Umum' }} -->
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.mobilephone : 'Tidak ada informasi kontak' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon
                        @click="dialog.info = true"
                        color="blue"
                        class="cursor-pointer">
                        mdi-information
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-card-text>
                  <v-card
                    flat
                    color="#f9dee2"
                    class="border-radius">
                    <v-card-text
                      class="d-flex align-center pb-0">
                      <div
                        class="body-1 font-weight-bold">
                        Total Bayar
                      </div>

                      <v-spacer />

                      <div
                        class="title font-weight-bold black--text">
                        {{ form.amount_paid_origin | price }}
                      </div>
                    </v-card-text>

                    <v-divider
                      class="my-2 mx-4"
                      style="border-top: 1px dashed #bbb;" />

                    <v-card-text
                      class="pt-0">
                      <v-list
                        flat
                        dense
                        color="transparent"
                        class="border-radius py-0">
                        <v-list-item
                          class="px-0">
                          <v-list-item-avatar
                            size="40"
                            class="mt-1"
                            style="border: 2px solid #ffffff;">
                            <v-img
                              contain
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="require('@/assets/image/footer/saldo.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold text-capitalize">
                              <!-- Pakai Saldo -->

                              <span
                                @click="sheet.form_saldo = true; reset_saldo();"
                                class="d-flex align-center cursor-pointer">
                                <v-icon color="#000" size="14">mdi-circle-edit-outline</v-icon>

                                <span
                                  class="ml-1">
                                  Pakai Saldo
                                </span>
                              </span>
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2 font-weight-light line-text-second black--text"
                              style="opacity: 70%;">
                              {{ selected.pelanggan.saldo | price }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-switch
                              v-model="form.switch_saldo"
                              inset
                              @change="changeSaldo()"
                              :disabled="selected.pelanggan.saldo < 1"
                              :color="set_color">
                            </v-switch>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="transparent"
                class="border-radius">
                <v-card-text
                  class="body-2 mt-4 pb-1">
                  Pilih multi payment
                </v-card-text>

                <no-ssr>
                  <swiper
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide
                      v-for="(item, index) in list.payment"
                      :key="index"
                      class="pt-3 loader_produk"
                      :class="index === 0 ? 'pl-4 pr-4' : index < list.payment.length - 1 ? 'pr-4' : 'pr-4'"
                      style="max-width: 150px;">
                      <div>
                        <v-btn
                          @click="selected.menu = index; check_amount()"
                          width="118"
                          rounded
                          elevation="0"
                          color="#ececec"
                          class="body-2 font-weight-bold text-capitalize mb-2"
                          :style="selected.menu === index ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                          <div
                            class="d-flex align-center justify-center">
                            <div>
                              <v-img
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare"
                                :src="item.icon"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="text-capitalize ml-2">
                              {{ item.title }}
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </swiper-slide>
                  </swiper>
                </no-ssr>

                <v-card-text
                  class="py-0"
                  style="height: calc(100vh - 482px); overflow: auto;">
                  <div
                    class="d-flex align-center mt-6"
                    :class="list.payment[selected.menu].check ? 'green--text' : ''">
                    <div
                      @click="list.payment[selected.menu].check = !list.payment[selected.menu].check ? true : false; to_check_amount();"
                      class="cursor-pointer">
                      <v-icon
                        :color="list.payment[selected.menu].check ? 'green' : ''">
                        {{ !list.payment[selected.menu].check ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}
                      </v-icon>
                    </div>

                    <div
                      @click="list.payment[selected.menu].check = !list.payment[selected.menu].check ? true : false"
                      class="body-1 font-weight-bold text-capitalize cursor-pointer ml-2">
                      {{ list.payment[selected.menu].title }}
                    </div>
                  </div>

                  <v-divider
                    class="mt-3 mb-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-1 font-weight-bold">
                      Grand Total
                    </div>

                    <v-spacer />

                    <div
                      class="body-1 font-weight-bold"
                      :style="`color: ${set_color};`">
                      {{ form.amount_paid_sum | price }}
                    </div>
                  </div>

                  <v-divider v-if="total_bayar > 0"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="total_bayar > 0"
                    class="d-flex align-center">
                    <div
                      class="body-1 font-weight-bold red--text">
                      Kekurangan
                    </div>

                    <v-spacer />

                    <div
                      class="body-1 font-weight-bold red--text">
                      {{ (parseInt(form.amount_paid_sum) - parseInt(total_bayar)) | price }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <!-- CASH -->
                  <div v-if="selected.menu === 0"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <v-row
                      align="center">
                      <v-col
                        :cols="list.payment[selected.menu].data.amount > 0 ? 8 : 9">
                        <ValidationProvider
                          name="list.payment[selected.menu].data.amount"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            Jumlah Bayar
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                            min="0"
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 10.000"
                            :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                            @focus="$event.target.select()"
                            @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                            @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                            v-model="list.payment[selected.menu].data.amount"
                            :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        :cols="list.payment[selected.menu].data.amount > 0 ? 4 : 3"
                        class="d-flex align-center justify-center">
                        <div
                          @click="list.payment[selected.menu].data.amount = parseInt(form.amount_paid_sum)"
                          class="body-1 font-weight-bold cursor-pointer">
                          Sama
                        </div>

                        <div v-if="list.payment[selected.menu].data.amount > 0"
                          @click="list.payment[selected.menu].data.amount = 0"
                          class="body-1 font-weight-bold ml-3 red--text cursor-pointer">
                          Hapus
                        </div>
                      </v-col>
                    </v-row>

                    <div class="body-2 mb-2">
                      Atau pilih pecahan dibawah ini:
                    </div>

                    <v-row>
                      <v-col
                        v-for="(item, index) in list.money"
                        :key="index"
                        cols="6">
                        <v-btn
                          @click="list.payment[selected.menu].data.amount += item"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                          <div
                            class="d-flex align-center justify-center btn_auth_in"
                            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                            {{ item | price }}
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- TRANSFER & EDC -->
                  <div v-if="selected.menu === 1 || selected.menu === 2"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <div
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nama Bank
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.bank = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_bank_name === ''">
                          Pilih
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_bank_name }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      class="mt-3 mb-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.referensi = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_ref === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_ref }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      class="mt-3 mb-8"
                      style="border-top: 1px dashed #bbb;" />

                    <ValidationProvider
                      name="list.payment[selected.menu].data.amount"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jumlah Bayar
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 10.000"
                        :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                        @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                        v-model="list.payment[selected.menu].data.amount"
                        :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </div>

                  <!-- QRIS & SHOPEEPAY -->
                  <div v-if="selected.menu === 3 || selected.menu === 4"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <div
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.referensi = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_ref === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_ref }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      class="mt-3 mb-8"
                      style="border-top: 1px dashed #bbb;" />

                    <ValidationProvider
                      name="list.payment[selected.menu].data.amount"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jumlah Bayar
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 10.000"
                        :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                        @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                        v-model="list.payment[selected.menu].data.amount"
                        :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col
                      cols="7"
                      class="py-0">
                      <v-btn
                        @click="dialog.admin = true; fetchAdmin();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in d-flex align-center justify-center">
                          <v-icon color="white">mdi-check-circle</v-icon>

                          <span
                            class="ml-2">
                            Simpan Transaksi
                          </span>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="5"
                      class="py-0">
                      <v-btn
                        to="/payment"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-arrow-left-circle
                          </v-icon>

                          <span
                            class="ml-8">
                            Kembali
                          </span>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </ValidationObserver>
        </section>

        <v-bottom-sheet
          v-model="sheet.form_saldo"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_saldo = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_saldo = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Saldo
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Saldo"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Saldo
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 100.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form_saldo.value"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider
                        name="Tipe"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tipe
                        </div>

                        <v-select
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Masuk"

                          :items="[
                            {
                              text: 'Masuk',
                              value: 'in'
                            },
                            {
                              text: 'Keluar',
                              value: 'out'
                            }
                          ]"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_saldo.type"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider> -->

                      <ValidationProvider
                        name="Metode Pembayaran"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Metode Pembayaran
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Cash"

                          :items="config.payment"
                          item-text="title"
                          item-value="title"
                          :item-color="set_color"

                          @change="form_saldo.payment_method_bank_name = ''; form_saldo.payment_method_ref = '';"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_saldo.payment_method"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:selection="{ item }">
                            <v-avatar
                              tile
                              size="20">
                              <v-img
                                alt="bidanvitacare"
                                :src="item.icon">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="body-2 text-capitalize ml-2">
                              {{ item.title }}
                            </span>
                          </template>

                          <template v-slot:item="{ item }">
                            <v-avatar
                              tile
                              size="20">
                              <v-img
                                alt="bidanvitacare"
                                :src="item.icon">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="body-2 text-capitalize ml-2">
                              {{ item.title }}
                            </span>
                          </template>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider v-if="form_saldo.payment_method === 'transfer' || form_saldo.payment_method === 'edc'"
                        name="Bank"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Bank
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. BCA"

                          :items="config.bank"
                          item-text="text"
                          item-value="text"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_saldo.payment_method_bank_name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:selection="{ item }">
                            <v-avatar
                              tile
                              size="20">
                              <v-img
                                alt="bidanvitacare"
                                :src="item.icon">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="body-2 text-capitalize ml-2">
                              {{ item.text }}
                            </span>
                          </template>

                          <template v-slot:item="{ item }">
                            <v-avatar
                              tile
                              size="20">
                              <v-img
                                alt="bidanvitacare"
                                :src="item.icon">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="body-2 text-capitalize ml-2">
                              {{ item.text }}
                            </span>
                          </template>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider v-if="form_saldo.payment_method !== '' && form_saldo.payment_method !== 'cash'"
                        name="Nomor Referensi"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nomor Referensi
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1234567890"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form_saldo.payment_method_ref"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Keterangan"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Keterangan
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-textarea
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan keterangan disini . . ."
                          :append-icon="form_saldo.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form_saldo.note"
                          :success="form_saldo.note !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/saldo.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Saldo untuk menambah saldo pelanggan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form_saldo"
                        :loading="process.form_saldo"
                        v-on:keyup.enter="form_saldo_before.value > 0 ? save_saldo() : save_saldo_final()"
                        @click="form_saldo_before.value > 0 ? save_saldo() : save_saldo_final()"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_saldo = false"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  moment.locale('id')
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          bank: false,
          referensi: false,
          info: false,

          admin: false
        },

        sheet: {
          form_saldo: false
        },

        search: '',

        list: {
          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.',
              active: false,
              check: false,
              data: {
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.',
              active: false,
              check: false,
              data: {
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.',
              active: false,
              check: false,
              data: {
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.',
              active: false,
              check: false,
              data: {
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran dapat dilakukan melalui Shopeepay.',
              active: false,
              check: false,
              data: {
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            }
          ],
          money: [1000, 5000, 10000, 20000, 50000, 100000],
          bank: [
            // {
            //   text: 'BCA',
            //   icon: require('@/assets/bank/bca.png')
            // },
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            // {
            //   text: 'BNI',
            //   icon: require('@/assets/bank/bni.png')
            // },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            },
            // {
            //   text: 'CIMB',
            //   icon: require('@/assets/bank/cimb.png')
            // }
          ],
          user_admin: []
        },

        config: {
          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.'
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.'
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.'
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.'
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran melalui paylater berjangka dari Shopeepay.'
            }
          ],
          bank: [
            // {
            //   text: 'BCA',
            //   icon: require('@/assets/bank/bca.png')
            // },
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            // {
            //   text: 'BNI',
            //   icon: require('@/assets/bank/bni.png')
            // },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            },
            // {
            //   text: 'CIMB',
            //   icon: require('@/assets/bank/cimb.png')
            // }
          ]
        },

        pagination: {},

        selected: {
          menu: 0,
          pelanggan: {},
          address: {},
          payment: {}
        },

        total_bayar: 0,

        form: {
          id: '',
          type: 'on_site',
          customer_id: '',
          customer_address_id: '',
          note: '',
          items: [],
          discount_percent: 0,
          discount_value: 0,
          tax_percent: 0,
          tax_value: 0,

          transport_id: '',
          transport_name: '',
          transport_cost: 0,
          transport_qty: 1,
          transport_total_cost: 0,

          dp_paid: 0,

          amount_paid_origin: 0,
          amount_paid_sum: 0,
          amount_paid: 0,
          status: 'complete',
          // payment_method: '',
          // payment_method_bank_name: '',
          // payment_method_ref: '',

          payment: [],

          switch_saldo: false,

          total_days: 0,

          // KASIR
          cashier_id: 0,
          cashier_name: '',

          // DOWN PAYMENT
          switch_dp: false,

          dp: 0,
          dp_payment_method: '',
          dp_payment_method_bank_name: '',
          dp_payment_method_ref: ''
        },

        form_saldo: {
          customer_id: '',
          type: 'in',
          value: '',
          note: '',

          payment_method: '',
          payment_method_bank_name: '',
          payment_method_ref: ''
        },

        form_saldo_before: {},

        process: {
          load: false,
          form: false,

          form_saldo: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/multi',
          title: 'Multi Payment',
          description: 'Multi Payment Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      kasir () {
        return this.$store.state.kasir
      }
    },
    mounted () {
      this.form = this.kasir.kasir
      this.selected.pelanggan = this.kasir.pelanggan
      this.selected.address = this.kasir.address !== undefined ? this.kasir.address : {}
      this.selected.payment = this.kasir.payment
    },
    methods: {
      async fetchAdmin () {
        this.process.load = true

        let params = {
          search: this.search,
          'role[eq]': 'admin'
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list.user_admin = response.results.data
            this.pagination = response.results.pagination

            this.form.cashier_id = this.user.id,
            this.form.cashier_name = this.user.name
          }
        })
      },

      changeSaldo () {
        this.form.dp_paid = !this.form.switch_saldo ? 0 : this.selected.pelanggan.saldo

        this.form.amount_paid_sum = !this.form.switch_saldo ? this.form.amount_paid_origin : parseInt(this.form.amount_paid_origin) - parseInt(this.form.dp_paid)

        this.form.amount_paid_sum = this.form.amount_paid_sum < 0 ? 0 : this.form.amount_paid_sum
      },

      to_check_amount () {
        if (!this.list.payment[this.selected.menu].check) {
          this.list.payment[this.selected.menu].data = {
            amount: 0,
            method: '',
            method_bank_name: '',
            method_ref: ''
          }

          this.check_amount()
        }
      },

      check_amount () {
        let item = this.list.payment

        this.total_bayar = 0

        item.map(obj => {
          this.total_bayar += obj.data.amount
        })
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/order/add' : 'admin/order/update'

          this.form.payment = []

          let payment_selected = this.list.payment

          payment_selected.map(obj => {
            if (obj.check) {
              this.form.amount_paid += parseInt(obj.data.amount)

              obj.data.method = obj.title

              this.form.payment.push(obj.data)
            }
          })

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
            .then((response) => {

              if (response.status === 200) {
                if (this.selected.pelanggan.mobilephone !== '' && this.selected.pelanggan.mobilephone !== null) {
                    this.sandWa(response.results.data.so_store_id, response.results.data.so_uuid, response.results.data)
                  } else {
                  this.process.form = false

                  let forms = {
                    id: '',
                    type: 'on_site',
                    customer_id: '',
                    customer_address_id: '',
                    note: '',
                    items: [],
                    discount_percent: 0,
                    discount_value: 0,
                    tax_percent: 0,
                    tax_value: 0,

                    transport_id: '',
                    transport_name: '',
                    transport_cost: 0,
                    transport_qty: 1,
                    transport_total_cost: 0,

                    dp_paid: 0,

                    amount_paid_origin: 0,
                    amount_paid_sum: 0,
                    amount_paid: 0,
                    status: 'complete',
                    // payment_method: '',
                    // payment_method_bank_name: '',
                    // payment_method_ref: '',

                    payment: [],

                    switch_saldo: false,

                    total_days: 0,

                    // KASIR
                    cashier_id: 0,
                    cashier_name: '',

                    // DOWN PAYMENT
                    switch_dp: false,

                    dp: 0,
                    dp_payment_method: '',
                    dp_payment_method_bank_name: '',
                    dp_payment_method_ref: ''
                  }

                  this.$store.commit('kasir/setKasir', forms)
                  Cookie.set('kasir', forms)

                  this.$store.commit('kasir/setPelanggan', {})
                  Cookie.set('pelanggan', {})

                  this.$store.commit('kasir/setAddress', {})
                  Cookie.set('address', {})

                  this.$store.commit('kasir/setPayment', {})
                  Cookie.set('payment', {})

                  this.$router.push({ path: '/kasir' })

                  window.open(`/invoice/${response.results.data.so_uuid}`, '_blank')
                }
              } else {
                this.process.form = false
                this.message.error = response.message
              }
            })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      format_price (num) {
        if (isNaN(num)) return 'Not a Number'

        let price = ''
        const reverseNumber = num
          .toString()
          .split('')
          .reverse()
          .join('')
        const arrReverseNumber = [...Array(reverseNumber.length).keys()]

        arrReverseNumber.map(index => {
          if (index % 3 === 0) price += reverseNumber.substr(index, 3) + '.'
        })

        return `Rp ${
          price.split('', price.length - 1)
          .reverse()
          .join('')
        }`
      },

      async sandWa (store_id, uuid, item) {
        this.message.error = ''

        // const isValid = await this.$refs.form.validate()
        // if (isValid) {
          this.process.form = true

          let url = 'admin/order/send_wa_invoice'
          let form = {
            mobilephone: this.selected.pelanggan.mobilephone.replace(/^0/, '62'),
            message: `Nota dari transaksi ${item.so_customer_name}, total bayar sebesar ${this.format_price(item.so_amount_paid)} pada tanggal ${moment(item.so_create_datetime).format('D MMM, YYYY HH:mm', 'id')}.\nTransaksi ${item.so_customer_name} ${`https://aplikasi.bidanvitacare.id/invoice/${uuid}`}\n____ \nMohon Kesediaannya Memberikan Ulasan Layanan Kami pada Hari Ini Melalui : https://aplikasi.bidanvitacare.id/feedback/${uuid}.`,
            store_id: store_id
          }

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              let forms = {
                id: '',
                type: 'on_site',
                customer_id: '',
                customer_address_id: '',
                note: '',
                items: [],
                discount_percent: 0,
                discount_value: 0,
                tax_percent: 0,
                tax_value: 0,

                transport_id: '',
                transport_name: '',
                transport_cost: 0,
                transport_qty: 1,
                transport_total_cost: 0,

                dp_paid: 0,

                amount_paid_origin: 0,
                amount_paid_sum: 0,
                amount_paid: 0,
                status: 'complete',
                // payment_method: '',
                // payment_method_bank_name: '',
                // payment_method_ref: '',

                payment: [],

                switch_saldo: false,

                total_days: 0,

                // KASIR
                cashier_id: 0,
                cashier_name: '',

                // DOWN PAYMENT
                switch_dp: false,

                dp: 0,
                dp_payment_method: '',
                dp_payment_method_bank_name: '',
                dp_payment_method_ref: ''
              }

              this.$store.commit('kasir/setKasir', forms)
              Cookie.set('kasir', forms)

              this.$store.commit('kasir/setPelanggan', {})
              Cookie.set('pelanggan', {})

              this.$store.commit('kasir/setAddress', {})
              Cookie.set('address', {})

              this.$store.commit('kasir/setPayment', {})
              Cookie.set('payment', {})

              this.$router.push({ path: '/kasir' })

              window.open(`/invoice/${uuid}`, '_blank')
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        // } else {
        //   // AUTO FOCUS ON TEXT-FIELD
        //   setTimeout(() => {
        //     const errors = Object.entries(this.$refs.form.errors)
        //       .map(([key, value]) => ({ key, value }))
        //       .filter(error => error['value'].length)
        //     this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
        //       behavior: 'smooth',
        //       block: 'center'
        //     })
        //   }, 10)
        // }
      },


      async reset_saldo () {
        if (this.$refs.form) this.$refs.form.reset()

        let params = {
          customer_id: this.selected.pelanggan.id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-saldo`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            if (response.results.data.length > 0) {
              let data = response.results.data[0]

              this.form_saldo = {
                customer_id: data.customer_id,
                type: 'in',
                value: data.saldo,
                note: 'Ubah DP dari Kasir',

                payment_method: data.payment_method,
                payment_method_bank_name: data.payment_method_bank_name,
                payment_method_ref: data.payment_method_ref
              }

              this.form_saldo_before = {
                customer_id: data.customer_id,
                type: 'out',
                value: data.saldo,
                note: 'Penarikan DP dari Kasir',

                payment_method: data.payment_method,
                payment_method_bank_name: data.payment_method_bank_name,
                payment_method_ref: data.payment_method_ref
              }
            }
          }
        })
      },

      async save_saldo () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form_saldo = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/customer-saldo/add`, this.form_saldo_before)
          .then((response) => {

            this.process.form_saldo = false

            if (response.status === 200) {
              this.save_saldo_final()
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async save_saldo_final () {
        this.process.form_saldo = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer-saldo/add`, this.form_saldo)
        .then((response) => {

          this.process.form_saldo = false

          if (response.status === 200) {
            this.fetchPelanggan(this.form_saldo.customer_id)
          } else {
            this.message.error = response.message
          }
        })
      },

      async fetchPelanggan (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {

          if (response.status === 200) {
            if (response.results.data.length > 0) {
              let data = response.results.data[0]

              this.selected.pelanggan = data

              this.$store.commit('kasir/setPelanggan', this.selected.pelanggan)
              Cookie.set('pelanggan', this.selected.pelanggan)

              this.changeSaldo()

              this.sheet.form_saldo = false
            }
          }
        })
      }
    }
  }
</script>

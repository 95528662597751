<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- pelanggan -->
        <v-dialog v-model="sheet.pelanggan"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Pelanggan
              </span>

              <v-spacer />

              <v-btn
                @click="sheet.pelanggan = false"
                icon
                class="body-1 font-weight-bold"
                :style="`color: ${set_color};`">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="mt-2 px-4">
              <v-text-field
                v-model="search_pelanggan"
                @click:clear="search_pelanggan = ''; fetchPelanggan();"
                v-on:keyup.enter="fetchPelanggan()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.pelanggan = false; sheet.form_pelanggan = true; reset_pelanggan();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_user.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Pelanggan
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="px-4 pb-0"
              style="height: calc(100vh - 185px); overflow: auto;">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list_pelanggan"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_pelanggan.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_pelanggan.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="selected.pelanggan = item; form.customer_id = item.id; form.customer_name = item.name; sheet.pelanggan = false;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }} ({{ item.age === null ? '-' : item.age }})
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.email !== '' ? item.email : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone !== '' ? item.mobilephone : '-' }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_pelanggan.total_data > 10 && limit_pelanggan < pagination_pelanggan.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit_pelanggan += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_pelanggan.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Pelanggan" />
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- form pelanggan -->
        <v-dialog v-model="sheet.form_pelanggan"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                {{ form_pelanggan.id === '' ? 'Tambah' : 'Ubah' }}
              </span>

              <v-spacer />

              <v-btn
                @click="sheet.form_pelanggan = false; reset_pelanggan(); sheet.pelanggan = true; fetchPelanggan();"
                icon
                class="body-1 font-weight-bold"
                :style="`color: ${set_color};`">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="mt-2 px-4 pb-0">
              <ValidationObserver
                ref="form_pelanggan">
                <v-form>
                  <ValidationProvider
                    name="Nama Lengkap"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Lengkap
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save_pelanggan()"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. John Doe"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form_pelanggan.name"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Tipe"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tipe
                    </div>

                    <v-select
                      v-on:keyup.enter="save_pelanggan()"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Bayi"

                      :items="config.type_pelanggan"
                      item-text="text"
                      item-value="value"
                      :item-color="set_color"

                      @change="form_pelanggan.hpht_date = ''"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form_pelanggan.type"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <div v-if="form_pelanggan.type === 'ibu_hamil'">
                    <div class="body-2 mb-2">
                      HPHT
                    </div>

                    <v-menu
                      ref="picker_hpht"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.hpht_date">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="HPHT"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2020-01-01"

                            :append-icon="form_pelanggan.hpht_date !== '' && valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form_pelanggan.hpht_date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form_pelanggan.hpht_date"
                            :success="form_pelanggan.hpht_date !== '' && valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-date-picker
                        :color="set_color"
                        v-model="form_pelanggan.hpht_date"
                        @input="picker.hpht_date = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <ValidationProvider
                    name="No. Handphone"
                    rules="min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      No. Handphone
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save_pelanggan()"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 081332XXXXXX"
                      :append-icon="valid && form_pelanggan.mobilephone !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form_pelanggan.mobilephone"
                      :success="valid && form_pelanggan.mobilephone !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="pa-4 pt-0">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/image/footer/pelanggan_bvc.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data Pelanggan untuk membuat akun di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_pelanggan()"
                    @click="save_pelanggan()"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_pelanggan = false; reset_pelanggan(); sheet.pelanggan = true; fetchPelanggan();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- pendamping -->
        <v-dialog v-model="dialog.pendamping"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pendamping
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  Nama Pendamping
                </div>

                <v-text-field
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. John Doe"

                  v-model="form.wali">
                </v-text-field>
              </div>

              <div>
                <div class="body-2 mb-2">
                  No. Handphone Pendamping
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 081332XXXXXX"

                  v-model="form.mobilephone_wali">
                </v-text-field>
              </div>

              <div>
                <div class="body-2 mb-2">
                  No. Handphone Lainnya
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 085702XXXXXX"

                  hide-details

                  v-model="form.mobilephone_other">
                </v-text-field>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.pendamping = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.pendamping = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- klien -->
        <v-dialog v-model="dialog.klien"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Klien Dipilih
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="selected.item_index_to_visit !== '' && form.details.length > 0"
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-btn
                @click="dialog.klien = false; dialog.klien_data = true; search_pelanggan = ''; fetchPelanggan();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mb-2"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Klien
                  </div>
                </div>
              </v-btn>

              <v-row>
                <v-col
                  v-for="(item, index) in form.details[selected.item_index_to_visit].visit"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="form.details[selected.item_index_to_visit].visit.length > 0"
                    flat
                    dense
                    three-line
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        class="mt-2"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.client_name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="body-2 font-weight-light line-text-second black--text"
                          style="opacity: 70%;">
                          <span v-if="item.therapist_name !== ''"
                            class="text-capitalize">
                            Terapis. {{ item.therapist_name }}
                          </span>

                          <span v-else>
                            Terapis belum ditentukan
                          </span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          class="body-2 font-weight-light line-text-second black--text">
                          <v-divider
                            class="my-2"
                            style="border-top: 1px dashed #bbb;" />

                          <div
                            class="d-flex align-center">
                            <div
                              style="opacity: 70%;">
                              Shift
                            </div>

                            <v-spacer />

                            <div>
                              {{ item.therapist_shift !== '' ? item.therapist_shift : '-' }}
                            </div>
                          </div>

                          <div
                            class="d-flex align-center" v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                            <div
                              style="opacity: 70%;">
                              Tanggal
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | date }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center" v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                            <div
                              style="opacity: 70%;">
                              Pukul
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <!-- <div
                            class="d-flex align-center">
                            <div
                              style="opacity: 70%;">
                              Toleransi
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.toleransi_datetime !== '' && item.toleransi_datetime !== null">
                                {{ item.toleransi_datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div> -->

                          <v-divider
                            class="my-2"
                            style="border-top: 1px dashed #bbb;" />
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          class="mt-2">
                          <v-btn
                            @click="toEditKlien(item, index)"

                            x-small
                            rounded
                            color="blue"
                            elevation="0"
                            class="caption white--text text-capitalize mr-1">
                            <v-icon
                              x-small
                              color="white">
                              mdi-circle-edit-outline
                            </v-icon>

                            <span
                              class="ml-1">
                              Ubah
                            </span>
                          </v-btn>

                          <v-btn
                            @click="form.details[selected.item_index_to_visit].visit.splice(index, 1)"

                            x-small
                            rounded
                            color="red"
                            elevation="0"
                            class="caption white--text text-capitalize">
                            <v-icon
                              x-small
                              color="white">
                              mdi-close-circle
                            </v-icon>

                            <span
                              class="ml-1">
                              Hapus
                            </span>
                          </v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- <v-icon
                        @click="form.details[selected.item_index_to_visit].visit.splice(index, 1)"
                        class="cursor-pointer"
                        color="red"
                        size="18"
                        style="position: absolute; right: 3px; top: -4px;">
                        mdi-close-circle
                      </v-icon> -->
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <Empty v-if="form.details[selected.item_index_to_visit].visit.length < 1"
                margin="my-8"
                size="125"
                message="Klien" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.klien = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.klien = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- pilih klien -->
        <v-dialog v-model="dialog.klien_data"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Klien
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_pelanggan"
                @click:clear="search_pelanggan = ''; fetchPelanggan();"
                v-on:keyup.enter="fetchPelanggan()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data klien ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list_pelanggan"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_pelanggan.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_pelanggan.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.details.length > 0 && selected.visit_klien_index === index ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="selected.visit_klien_object = item; selected.visit_klien_index = index; form_visit.client_id = item.id;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;"
                              :style="selected.visit_klien_index === index ? 'position: relative; top: -235px;' : ''">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }} ({{ item.age === null ? '-' : item.age }})
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                Email. {{ item.email !== '' ? item.email : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone !== '' ? item.mobilephone : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle v-if="selected.visit_klien_index === index">

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />

                                  <div v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                                    <div class="body-2 mb-2">
                                      Tanggal Visit
                                    </div>

                                    <v-menu
                                      ref="visit_date"
                                      min-width="290px"
                                      max-width="290px"
                                      transition="scale-transition"
                                      offset-y
                                      :nudge-right="40"
                                      :close-on-content-click="false"
                                      v-model="item.picker.visit_date">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          readonly
                                          filled
                                          rounded
                                          dense
                                          :color="set_color"
                                          placeholder="Contoh. 2023-01-01"

                                          clearable
                                          @click:clear="form_visit.date = ''"
                                          v-on="on"
                                          v-bind="attrs"

                                          v-model="form_visit.date">
                                        </v-text-field>
                                      </template>

                                      <v-date-picker
                                        :color="set_color"
                                        v-model="form_visit.date"
                                        @input="item.picker.visit_date = false">
                                      </v-date-picker>
                                    </v-menu>
                                  </div>

                                  <div class="body-2 mb-2">
                                    Terapis
                                  </div>

                                  <v-autocomplete
                                    filled
                                    rounded
                                    dense
                                    :color="set_color"
                                    placeholder="Contoh. John Doe"

                                    :search-input.sync="search_terapis"
                                    :loading="process.terapis"

                                    :items="list_terapis"
                                    item-value="id"
                                    item-text="name"
                                    :item-color="set_color"

                                    :no-filter="false"

                                    @change="checkDatetimeAvailable(); form.details[selected.item_index_to_visit].type !== 'product' ? fetchShiftInput() : '';"

                                    v-model="form_visit.therapist_id">
                                    <template v-slot:item="data">
                                      <v-avatar
                                        size="24">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="body-2 text-capitalize ml-2">
                                        {{ data.item.name }}
                                      </span>
                                    </template>

                                    <template v-slot:selection="data">
                                      <v-avatar
                                        size="24">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="body-2 text-capitalize ml-2">
                                        {{ data.item.name }}
                                      </span>
                                    </template>
                                  </v-autocomplete>
                                </div>

                                <div>
                                  <div class="body-2 mb-2">
                                    Shift
                                  </div>

                                  <v-select
                                    filled
                                    rounded
                                    dense
                                    :color="set_color"
                                    placeholder="Contoh. Shift 1 (Pagi)"

                                    :items="list_shift"
                                    item-text="name"
                                    item-value="name"
                                    :item-color="set_color"

                                    v-model="form_visit.therapist_shift">
                                  </v-select>
                                </div>

                                <div v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                                  <div class="body-2 mb-2">
                                    Waktu Visit
                                  </div>

                                  <v-menu
                                    ref="visit_time"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="form_visit.time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    v-model="item.picker.visit_time">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        readonly
                                        filled
                                        rounded
                                        dense
                                        :color="set_color"
                                        placeholder="Contoh. 09:00"

                                        :disabled="form_visit.date === ''"

                                        clearable
                                        @click:clear="form_visit.time = ''"
                                        v-on="on"
                                        v-bind="attrs"

                                        v-model="form_visit.time">
                                      </v-text-field>
                                    </template>

                                    <v-time-picker
                                      v-if="item.picker.visit_time"
                                      v-model="form_visit.time"
                                      full-width
                                      :color="set_color"
                                      format="24hr"
                                      @click:minute="$refs.visit_time[0].save(`${form_visit.time}:00`); checkDatetimeAvailable();">
                                    </v-time-picker>
                                  </v-menu>
                                </div>

                                <!-- <div>
                                  <div class="body-2 mb-2">
                                    Waktu Toleransi
                                  </div>

                                  <v-menu
                                    ref="visit_toleransi_time"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="form_visit.toleransi_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    v-model="item.picker.visit_toleransi_time">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        readonly
                                        filled
                                        rounded
                                        dense
                                        :color="set_color"
                                        placeholder="Contoh. 08:30"

                                        :disabled="form_visit.date === ''"

                                        clearable
                                        @click:clear="form_visit.toleransi_time = ''"
                                        v-on="on"
                                        v-bind="attrs"

                                        hide-details

                                        v-model="form_visit.toleransi_time">
                                      </v-text-field>
                                    </template>

                                    <v-time-picker
                                      v-if="item.picker.visit_toleransi_time"
                                      v-model="form_visit.toleransi_time"
                                      full-width
                                      :color="set_color"
                                      format="24hr"
                                      @click:minute="$refs.visit_toleransi_time[0].save(`${form_visit.toleransi_time}:00`); checkDatetimeAvailable();">
                                    </v-time-picker>
                                  </v-menu>
                                </div> -->
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_pelanggan.total_data > 10 && limit_pelanggan < pagination_pelanggan.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit_pelanggan += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_pelanggan.total_page < 1"
                margin="my-8"
                size="125"
                message="Klien" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.klien_data = false; dialog.klien = true; addKlien(selected.visit_klien_object, selected.visit_klien_index)"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.klien_data = false; dialog.klien = true;"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- ubah klien terpilih -->
        <v-dialog v-model="dialog.klien_data_edit"
          v-if="dialog.klien_data_edit"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Ubah Data Klien
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <div>
                <div class="body-2 mb-2">
                  Klien
                </div>

                <v-autocomplete
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. Cintya Zenit"

                  :search-input.sync="search_klien"
                  :loading="process.klien"

                  :items="list_klien"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  :no-filter="false"

                  v-model="form_visit.client_id">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }} ({{ data.item.age === null ? '-' : data.item.age }})
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }} ({{ data.item.age === null ? '-' : data.item.age }})
                    </span>
                  </template>
                </v-autocomplete>
              </div>


              <div v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                <div class="body-2 mb-2">
                  Tanggal Visit
                </div>

                <v-menu
                  ref="visit_date_ubah"
                  min-width="290px"
                  max-width="290px"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  :close-on-content-click="false"
                  v-model="picker.visit_date">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 2023-01-01"

                      clearable
                      @click:clear="form_visit.date = ''"
                      v-on="on"
                      v-bind="attrs"

                      v-model="form_visit.date">
                    </v-text-field>
                  </template>

                  <v-date-picker
                    :color="set_color"
                    v-model="form_visit.date"
                    @input="picker.visit_date = false">
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <div class="body-2 mb-2">
                  Terapis
                </div>

                <v-autocomplete
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. John Doe"

                  :search-input.sync="search_terapis"
                  :loading="process.terapis"

                  :items="list_terapis"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  :no-filter="false"

                  @change="checkDatetimeAvailable(); form.details[selected.item_index_to_visit].type !== 'product' ? fetchShiftInput() : '';"

                  v-model="form_visit.therapist_id">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>

              <div>
                <div class="body-2 mb-2">
                  Shift
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. Shift 1 (Pagi)"

                  :items="list_shift"
                  item-text="name"
                  item-value="name"
                  :item-color="set_color"

                  v-model="form_visit.therapist_shift">
                </v-select>
              </div>

              <div v-if="form.details[selected.item_index_to_visit].type !== 'product'">
                <div class="body-2 mb-2">
                  Waktu Visit
                </div>

                <v-menu
                  ref="visit_time_ubah"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form_visit.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  v-model="picker.visit_time">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 09:00"

                      :disabled="form_visit.date === ''"

                      clearable
                      @click:clear="form_visit.time = ''"
                      v-on="on"
                      v-bind="attrs"

                      v-model="form_visit.time">
                    </v-text-field>
                  </template>

                  <v-time-picker
                    v-if="picker.visit_time"
                    v-model="form_visit.time"
                    full-width
                    :color="set_color"
                    format="24hr"
                    @click:minute="$refs.visit_time_ubah.save(`${form_visit.time}:00`); checkDatetimeAvailable();">
                  </v-time-picker>
                </v-menu>
              </div>

              <!-- <div>
                <div class="body-2 mb-2">
                  Waktu Toleransi
                </div>

                <v-menu
                  ref="visit_toleransi_time_ubah"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form_visit.toleransi_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  v-model="picker.visit_toleransi_time">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 08:30"

                      :disabled="form_visit.date === ''"

                      clearable
                      @click:clear="form_visit.toleransi_time = ''"
                      v-on="on"
                      v-bind="attrs"

                      v-model="form_visit.toleransi_time">
                    </v-text-field>
                  </template>

                  <v-time-picker
                    v-if="picker.visit_toleransi_time"
                    v-model="form_visit.toleransi_time"
                    full-width
                    :color="set_color"
                    format="24hr"
                    @click:minute="$refs.visit_toleransi_time_ubah.save(`${form_visit.toleransi_time}:00`); checkDatetimeAvailable();">
                  </v-time-picker>
                </v-menu>
              </div> -->
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.klien_data_edit = false; dialog.klien = true; editKlien()"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.klien_data_edit = false; dialog.klien = true;"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- alamat -->
        <v-dialog v-model="dialog.address"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Alamat
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_address"
                @click:clear="search_address = ''; fetchAddress();"
                v-on:keyup.enter="fetchAddress()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data alamat ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list_address"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_address.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_address.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.customer_address_id === item.id ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="addAddress(item)">
                            <v-list-item-avatar
                              tile
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.address_full }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.note !== '' ? item.note : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.subdistrict_name }}, {{ item.city_name }}, {{ item.province_name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination_address.total_page < 1"
                margin="my-8"
                size="125"
                message="Alamat" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.address = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.address = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- saldo -->
        <v-dialog v-model="dialog.saldo"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Down Payment
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-2">
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="Down Payment"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Down Payment
                    </div>

                    <v-text-field
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 100.000"
                      :append-icon="valid && form.dp !== '' ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      @blur="form.dp = form.dp === '' ? 0 : form.dp"

                      v-model="form.dp"
                      :success="valid && form.dp !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Metode Pembayaran"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Metode Pembayaran
                    </div>

                    <v-select
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Cash"

                      :items="config.payment"
                      item-text="title"
                      item-value="title"
                      :item-color="set_color"

                      @change="form.dp_payment_method_bank_name = ''; form.dp_payment_method_ref = '';"

                      clearable
                      @click:clear="form.dp_payment_method = ''; form.dp_payment_method_bank_name = ''; form.dp_payment_method_ref = '';"

                      :append-icon="valid && form.dp_payment_method !== '' && form.dp_payment_method !== null ? 'mdi-check-circle-outline' : ''"
                      v-model="form.dp_payment_method"
                      :success="valid && form.dp_payment_method !== '' && form.dp_payment_method !== null"
                      :error-messages="errors"
                      required>
                      <template v-slot:selection="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.title }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.title }}
                        </span>
                      </template>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.dp_payment_method === 'transfer' || form.dp_payment_method === 'edc'"
                    name="Bank"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Bank
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. BCA"

                      :items="config.bank"
                      item-text="text"
                      item-value="text"
                      :item-color="set_color"

                      :append-icon="valid && form.dp_payment_method_bank_name !== '' && form.dp_payment_method_bank_name !== null ? 'mdi-check-circle-outline' : ''"
                      v-model="form.dp_payment_method_bank_name"
                      :success="valid && form.dp_payment_method_bank_name !== '' && form.dp_payment_method_bank_name !== null"
                      :error-messages="errors"
                      required>
                      <template v-slot:selection="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.text }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.text }}
                        </span>
                      </template>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.dp_payment_method !== '' && form.dp_payment_method !== null && form.dp_payment_method !== 'cash'"
                    name="Nomor Referensi"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Nomor Referensi
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 1234567890"
                      :append-icon="valid && form.dp_payment_method_ref !== '' && form.dp_payment_method_ref !== null ? 'mdi-check-circle-outline' : ''"

                      v-model="form.dp_payment_method_ref"
                      :success="valid && form.dp_payment_method_ref !== '' && form.dp_payment_method_ref !== null"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_dp()"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.saldo = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- catatan khusus admin -->
        <v-dialog v-model="dialog.note_khusus"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Catatan Khusus Admin
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-0">
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="Catatan Khusus Admin"
                    v-slot="{ errors, valid }">
                    <v-textarea
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan catatan khusus admin disini . . ."
                      :append-icon="form.note_khusus !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.note_khusus"
                      :success="form.note_khusus !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.note_khusus = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.note_khusus = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Penugasan admin -->
        <v-dialog v-model="dialog.single_partner"
          persistent
          width="350"
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Penugasan
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second pb-0">
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="Pilih Penugasan"
                    v-slot="{ errors }">
                    <v-radio-group
                      v-model="selected.single_partner"
                      :color="set_color"
                      required
                      :error-messages="errors">
                      <v-radio
                        label="Penugasan Single"
                        :color="set_color"
                        value="Single">
                      </v-radio>

                      <v-radio
                        label="Penugasan Partner"
                        :color="set_color"
                        value="Partner">
                      </v-radio>
                    </v-radio-group>
                    <!-- <v-textarea
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan catatan khusus admin disini . . ."
                      :append-icon="form.note_khusus !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.note_khusus"
                      :success="form.note_khusus !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea> -->
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="form.single_partner = selected.single_partner; dialog.single_partner = false"
                :disabled="selected.single_partner === ''"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.single_partner = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- transport -->
        <v-dialog v-model="dialog.transport"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Transport
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_transport"
                @click:clear="search_transport = ''; fetchTransport();"
                v-on:keyup.enter="fetchTransport()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data transport ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list_transport"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_transport.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_transport.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.transport_id === item.id ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="addTransport(item)">
                            <v-list-item-avatar
                              tile
                              size="40"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;"
                              :style="form.transport_id === item.id ? 'position: relative; top: -18px;' : ''">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize mb-1">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2"
                                :class="form.transport_id === item.id ? 'mt-1 mb-3' : 'mt-1'">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : ''">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle v-if="form.transport_id === item.id">
                                <!-- transport qty -->
                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      <v-icon
                                        @click="qtyTransportMin()"
                                        :disabled="form.transport_qty < 2"
                                        class="cursor-pointer"
                                        size="28"
                                        color="red">
                                        mdi-minus-circle
                                      </v-icon>
                                    </div>

                                    <div
                                      class="input_qty px-1">
                                      <v-text-field
                                        type="number"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                                        min="1"
                                        filled
                                        rounded
                                        dense
                                        :color="set_color"

                                        @focus="$event.target.select()"
                                        @keyup="form.transport_qty = form.transport_qty === '' || parseInt(form.transport_qty) < 1 ? 1 : form.transport_qty; qtyTransportUpdate();"
                                        @blur="form.transport_qty = form.transport_qty === '' || parseInt(form.transport_qty) < 1 ? 1 : form.transport_qty; qtyTransportUpdate();"

                                        hide-details

                                        v-model.number="form.transport_qty">
                                      </v-text-field>
                                    </div>

                                    <div>
                                      <v-icon
                                        @click="qtyTransportPlus()"
                                        class="cursor-pointer"
                                        size="28"
                                        color="green">
                                        mdi-plus-circle
                                      </v-icon>
                                    </div>
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination_transport.total_page < 1"
                margin="my-8"
                size="125"
                message="Transport" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.transport = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.transport = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- check available -->
        <v-dialog v-model="dialog.check_available"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Jadwal Bentrok
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="check_datetime_available.length > 0"
              class="body-2 text-first line-text-second px-4 pb-2">
              <v-row>
                <v-col
                  v-for="(item, index) in check_datetime_available"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="check_datetime_available.length > 0"
                    flat
                    dense
                    three-line
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        tile
                        class="mt-3"
                        style="border: 2px solid #ffffff; border-radius: 5px !important;">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div
                          class="body-2 font-weight-bold text-capitalize">
                          <span
                            class="two-line mb-1">
                            {{ item.item_name }}
                          </span>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div>
                            Durasi. <strong>{{ item.item_duration_minutes }} Menit</strong>
                          </div>

                          <v-spacer />

                          <div>
                            Qty. <strong>{{ item.item_qty }}</strong>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <!-- klien -->
                        <div
                          class="d-flex align-center">
                          <div>
                            Klien
                          </div>

                          <v-spacer />

                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="caption font-weight-bold one-line text-capitalize ml-1">
                              {{ item.client_name }}
                            </span>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <div>
                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Tanggal
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | date }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Pukul
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center font-weight-light">
                            <div
                              style="opacity: 70%;">
                              Toleransi
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.toleransi_datetime !== '' && item.toleransi_datetime !== null">
                                {{ item.toleransi_datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>
              <v-btn
                @click="dialog.check_available = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="sheet.error">
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Error
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="mt-3 pb-2">
              <ul
                style="position: relative; left: -10px;">
                <li
                  v-for="(item, index) in message.errors"
                  :key="index"
                  class="red--text"
                  :class="index < message.errors.length - 1 ? 'mb-2' : ''"
                  v-html="item">
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-row>
            <v-col
              cols="12"
              md="8">
              <v-card
                flat
                class="ml-4 mt-5"
                height="calc(100vh - 110px)"
                style="border: 2px dashed #bbb; border-radius: 15px;">
                <v-card-text
                  class="px-4 pb-3">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="selected.item"
                        filled
                        rounded
                        dense
                        v-on:change="fetchItem()"
                        :items="config.type_item"
                        item-text="text"
                        item-value="value"
                        single-line
                        hide-details
                        :color="set_color"
                        placeholder="Kategori"
                        clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="search_item"
                        @click:clear="search_item = ''; fetchItem();"
                        v-on:keyup.enter="fetchItem()"
                        filled
                        rounded
                        dense
                        single-line
                        hide-details
                        :color="set_color"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Cari data yang Anda butuhkan ?"
                        clearable>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text
                  class="py-0"
                  style="overflow: auto;"
                  :style="config.type_item.length > 1 ? 'height: calc(100vh - 200px);' : 'height: calc(100vh - 145px);'">
                  <v-row>
                    <v-col
                      v-for="(item, index) in process.load_item ? 9 : list_item"
                      :key="index"
                      cols="4">
                      <v-skeleton-loader
                        :loading="process.load_item"
                        type="list-item-avatar-three-line">
                        <template>
                          <div
                            style="border-radius: 15px;"
                            :style="!checkItem(item.id) ? 'border: 2px dashed #bbb;' : `border: 2px dashed ${set_color};`">
                            <v-list
                              v-if="!process.load_item && list_item.length > 0"
                              flat
                              dense
                              three-line
                              color="#FFFFFF40"
                              class="border-radius">
                              <v-list-item
                                @click="addItem(item)">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  class="mt-2"
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div v-if="item.type !== 'material'">
                                    <v-chip
                                      x-small
                                      class="caption white--text text-capitalize"
                                      :color="set_color"
                                      style="margin-bottom: 5px; max-width: 245px;">
                                      <span
                                        class="one-line">
                                        {{ item.category_name !== '' ? item.category_name : '-' }}
                                      </span>
                                    </v-chip>
                                  </div>

                                  <v-list-item-title
                                    class="body-2 font-weight-bold text-capitalize">
                                    {{ item.name }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light line-text-second black--text"
                                    style="opacity: 70%;">
                                    <span v-if="item.type !== 'service'">
                                      Stok. {{ item.stock }}
                                    </span>

                                    <span v-else>
                                      Durasi. {{ item.duration_minutes }} Menit
                                    </span>
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle v-if="item.type !== 'material'"
                                    class="body-2">
                                    <span
                                      :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                      {{ item.retail_price | price }}
                                    </span>

                                    <span v-if="item.special_price > 0"
                                      class="font-weight-bold ml-2">
                                      {{ item.special_price | price }}
                                    </span>
                                  </v-list-item-subtitle>

                                  <div v-if="item.type === 'material'"
                                    class="pa-3 mt-2"
                                    style="border: 2px dashed #bbb; border-radius: 10px;">
                                    <ul v-if="item.composition_json_array.length > 0"
                                      class="body-2 font-weight-light black--text"
                                      style="position: relative; left: -10px;">
                                      <li
                                        v-for="(item_alat, index_alat) in item.composition_json_array"
                                        :key="index_alat"
                                        :class="index_alat < item.composition_json_array.length - 1 ? 'mb-4' : ''">
                                        <div
                                          class="mb-2">
                                          {{ item_alat.name }}
                                        </div>

                                        <div
                                          v-for="(item_stok, index_stok) in item_alat.stock"
                                          :key="index_stok">
                                          <div
                                            class="font-weight-bold mb-1"
                                            style="opacity: 60%;">
                                            {{ item_stok.store_name }}
                                          </div>

                                          <div
                                            class="d-flex align-center">
                                            <div>
                                              <div
                                                class="caption"
                                                style="opacity: 60%;">
                                                Stok
                                              </div>

                                              <div>
                                                {{ item_stok.stock }}
                                              </div>
                                            </div>

                                            <v-spacer />

                                            <div>
                                              <div
                                                class="caption"
                                                style="opacity: 60%;">
                                                Minimal Stok
                                              </div>

                                              <div>
                                                {{ item_stok.stock_min }}
                                              </div>
                                            </div>
                                          </div>

                                          <v-divider
                                            v-if="index_stok < item_alat.stock.length - 1"
                                            class="my-3"
                                            style="border-top: 1px dashed #bbb;" />
                                        </div>
                                      </li>
                                    </ul>

                                    <div v-else>
                                      Tidak ada kelengkapan alat
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>

                              <div v-if="checkItem(item.id)"
                                style="position: absolute; top: -6px; right: -6px; background: #ffffff; border-radius: 50px;">
                                <v-icon
                                  color="green">
                                  mdi-check-circle
                                </v-icon>
                              </div>
                            </v-list>
                          </div>
                        </template>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <div v-if="!process.load_item && pagination_item.total_data > 15 && limit_item < pagination_item.total_data"
                    class="text-center mt-3">
                    <v-btn
                      @click="limit_item += 15"
                      :loading="process.limit_item"
                      small
                      rounded
                      outlined
                      elevation="0"
                      :color="set_color"
                      class="text-capitalize"
                      :style="`color: ${set_color};`">
                      Lebih banyak
                    </v-btn>
                  </div>

                  <Empty v-if="!process.load_item && pagination_item.total_page < 1"
                    class="py-16"
                    margin="my-16"
                    size="125"
                    message="Item" />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="4">
              <v-card
                flat
                class="mr-4 mt-5"
                height="calc(100vh - 110px)"
                style="border: 2px dashed #bbb; border-radius: 15px;">
                <v-list
                  flat
                  dense
                  color="transparent">
                  <v-list-item>
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-icon v-if="Object.keys(selected.pelanggan).length > 0"
                        @click="selected.pelanggan = {}; customer_id = '';"
                        class="cursor-pointer"
                        color="red"
                        size="18"
                        style="position: absolute; left: -15px; top: -15px;">
                        mdi-close-circle
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }} <span v-if="Object.keys(selected.pelanggan).length > 0">
                          ({{ selected.pelanggan.age === null ? '-' : selected.pelanggan.age }})
                        </span>
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        <span v-if="Object.keys(selected.pelanggan).length > 0">
                          {{ selected.pelanggan.mobilephone | phone }}
                        </span>

                        <span v-else>
                          Tidak ada informasi kontak
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <div
                        class="d-flex align-center">
                        <v-icon
                          @click="sheet.pelanggan = true; search_pelanggan = ''; fetchPelanggan();"
                          class="cursor-pointer"
                          :color="set_color">
                          mdi-account-search
                        </v-icon>

                        <v-icon
                          @click="dialog.pendamping = true"
                          class="cursor-pointer ml-2">
                          mdi-account-child
                        </v-icon>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider
                  style="border-top: 2px dashed #bbb;" />

                <v-card-text
                  class="d-flex align-center item_type">
                  <div>
                    Jml Item (<strong>{{ form.details.length }}</strong>)
                  </div>

                  <v-spacer />

                  <div>
                    <div
                      class="d-flex align-center">
                      <v-select
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Tipe"

                        :items="config.type"
                        item-text="text"
                        item-value="value"
                        :item-color="set_color"

                        @change="form.customer_address_id = ''; selected.address = {};"

                        hide-details

                        v-model="form.type">
                      </v-select>
                    </div>
                  </div>
                </v-card-text>

                <!-- jika item kosong -->
                <v-card v-if="form.details.length < 1"
                  flat
                  color="transparent"
                  class="py-16">
                  <v-card-text
                    class="text-center py-16 my-16">
                    <v-img
                      contain
                      width="200"
                      height="auto"
                      class="ma-auto"
                      alt="bidanvitacare"
                      :src="require('@/assets/logo/favicon.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      style="position: relative; top: -35px;">
                      <div>
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>

                      <div
                        class="mt-2">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- item -->
                <v-card v-if="form.details.length > 0"
                  flat
                  color="transparent">
                  <v-card-text
                    style="overflow: auto;"
                    :style="form.type === 'on_site' ? `height: calc(100vh - 417px);` : `height: calc(100vh - 465px);`">
                    <v-row>
                      <v-col
                        v-for="(item, index) in form.details"
                        :key="index"
                        :class="index < form.details.length - 1 ? 'mb-4' : ''"
                        cols="12"
                        class="py-0">
                        <v-card
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list
                            flat
                            dense
                            three-line
                            color="transparent">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                tile
                                class="mt-3"
                                style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                <v-img
                                  contain
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div
                                  class="mb-2">
                                  <v-chip
                                    x-small
                                    color="#424242"
                                    class="white--text d-flex justify-center"
                                    style="width: 65px;">
                                    {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                  </v-chip>
                                </div>

                                <div
                                  class="body-2 font-weight-bold text-capitalize">
                                  <span
                                    class="two-line mb-1">
                                    {{ item.item_name }}
                                  </span>
                                </div>

                                <div>
                                  Durasi. <strong>{{ item.item_duration_minutes }} Menit</strong>
                                </div>

                                <div>
                                  <v-divider
                                    class="mt-2 mb-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      <v-icon
                                        @click="qtyMin(index)"
                                        :disabled="item.qty < 2"
                                        class="cursor-pointer"
                                        size="28"
                                        color="red">
                                        mdi-minus-circle
                                      </v-icon>
                                    </div>

                                    <div
                                      class="input_qty px-1">
                                      <v-text-field
                                        type="number"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                                        min="1"
                                        filled
                                        rounded
                                        dense
                                        :color="set_color"

                                        @focus="$event.target.select()"
                                        @keyup="item.qty = item.qty === '' || parseInt(item.qty) < 1 ? 1 : item.qty;"
                                        @blur="item.qty = item.qty === '' || parseInt(item.qty) < 1 ? 1 : item.qty;"

                                        hide-details

                                        v-model.number="item.qty">
                                      </v-text-field>
                                    </div>

                                    <div>
                                      <v-icon
                                        @click="qtyPlus(index)"
                                        class="cursor-pointer"
                                        size="28"
                                        color="green">
                                        mdi-plus-circle
                                      </v-icon>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Klien
                                  </div>

                                  <v-spacer />

                                  <div
                                    @click="selected.item_index_to_visit = index; dialog.klien = true;">
                                    <div v-if="item.visit.length < 1"
                                      class="d-flex align-center cursor-pointer"
                                      style="opacity: 60%;">
                                      <v-icon color="black" size="14">mdi-account-supervisor-circle-outline</v-icon>

                                      <span
                                        class="caption ml-1">
                                        Tambah
                                      </span>
                                    </div>

                                    <div v-else
                                      class="d-flex align-center cursor-pointer">
                                      <v-avatar
                                        v-for="(item_visit, index_visit) in item.visit"
                                        :key="index_visit"
                                        v-show="index_visit < 2"
                                        size="24"
                                        class="mt-0"
                                        style="border: 2px solid #ffffff;"
                                        :style="item.visit.length === 2 && index_visit === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                item.visit.length > 2 && index_visit === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                item.visit.length > 2 && index_visit === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">

                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="item_visit.client_image_url !== '' ? item_visit.client_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span v-if="item.visit.length < 2"
                                        class="caption blue--text font-weight-bold one-line text-capitalize ml-1"
                                        style="width: 50px;">
                                        {{ item.visit[0].client_name }}
                                      </span>

                                      <span v-if="item.visit.length > 2"
                                        class="caption blue--text font-weight-bold ml-1">
                                        +{{ item.visit.length - 2 }}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div v-if="item.visit.length > 0">
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <v-row v-if="item.visit.length > 0">
                                  <v-col
                                    v-for="(item_visit, index_visit) in item.visit"
                                    :key="index_visit"
                                    cols="12"
                                    class="py-2">
                                    <v-list
                                      flat
                                      dense
                                      three-line
                                      color="#FFFFFF40"
                                      class="border-radius"
                                      style="border: 1px dashed #bbb;">
                                      <v-list-item>
                                        <v-list-item-avatar
                                          size="40"
                                          class="mt-2"
                                          style="border: 2px solid #ffffff;">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item_visit.client_image_url !== '' ? item_visit.client_image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                          <v-list-item-title
                                            class="body-2 font-weight-bold text-capitalize">
                                            {{ item_visit.client_name }}
                                          </v-list-item-title>

                                          <v-list-item-subtitle
                                            class="body-2 font-weight-light line-text-second black--text"
                                            style="opacity: 70%;">
                                            <span v-if="item_visit.therapist_name !== ''"
                                              class="text-capitalize">
                                              Terapis. {{ item_visit.therapist_name }}
                                            </span>

                                            <span v-else>
                                              Terapis belum ditentukan
                                            </span>
                                          </v-list-item-subtitle>

                                          <v-list-item-subtitle
                                            class="body-2 font-weight-light line-text-second black--text">
                                            <v-divider
                                              class="my-2"
                                              style="border-top: 1px dashed #bbb;" />

                                            <div
                                              class="d-flex align-center">
                                              <div
                                                style="opacity: 70%;">
                                                Shift
                                              </div>

                                              <v-spacer />

                                              <div>
                                                {{ item_visit.therapist_shift !== '' ? item_visit.therapist_shift : '-' }}
                                              </div>
                                            </div>

                                            <div
                                              class="d-flex align-center" v-if="item.type !== 'product'">
                                              <div
                                                style="opacity: 70%;">
                                                Tanggal
                                              </div>

                                              <v-spacer />

                                              <div>
                                                <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                  {{ item_visit.datetime | date }}
                                                </span>

                                                <span v-else>
                                                  -
                                                </span>
                                              </div>
                                            </div>

                                            <div
                                              class="d-flex align-center" v-if="item.type !== 'product'">
                                              <div
                                                style="opacity: 70%;">
                                                Pukul
                                              </div>

                                              <v-spacer />

                                              <div>
                                                <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                  {{ item_visit.datetime | time }}
                                                </span>

                                                <span v-else>
                                                  -
                                                </span>
                                              </div>
                                            </div>

                                            <!-- <div
                                              class="d-flex align-center">
                                              <div
                                                style="opacity: 70%;">
                                                Toleransi
                                              </div>

                                              <v-spacer />

                                              <div>
                                                <span v-if="item_visit.toleransi_datetime !== '' && item_visit.toleransi_datetime !== null">
                                                  {{ item_visit.toleransi_datetime | time }}
                                                </span>

                                                <span v-else>
                                                  -
                                                </span>
                                              </div>
                                            </div> -->
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>

                                <div>
                                  <v-divider
                                    class="mt-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-icon
                            @click="form.details.splice(index, 1)"
                            class="cursor-pointer"
                            color="red"
                            size="18"
                            style="position: absolute; left: 0px; top: 0px;">
                            mdi-close-circle
                          </v-icon>

                          <!-- note -->
                          <div>
                            <div v-if="!item.active">
                              <div
                                @click="item.active = true"
                                class="d-flex align-center pa-4 pt-0 cursor-pointer"
                                style="opacity: 60%;">
                                <v-icon color="black" size="14">mdi-circle-edit-outline</v-icon>

                                <span
                                  class="caption ml-1 one-line">
                                  {{ item.note !== '' ? item.note : 'Tuliskan catatan disini.' }}
                                </span>
                              </div>
                            </div>

                            <div v-else
                              class="pa-4 pt-0">
                              <v-textarea
                                v-on:keyup.enter="item.active = false"
                                @blur="item.active = false"
                                @focus="$event.target.select()"

                                filled
                                rounded
                                dense
                                :color="set_color"
                                rows="2"
                                placeholder="Tuliskan catatan disini."

                                hide-details

                                v-model="item.note"
                                style="border-radius: 10px !important;">
                              </v-textarea>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- save -->
                <v-card v-if="form.details.length > 0"
                  flat
                  color="transparent"
                  min-height="50"
                  style="border-radius: 15px 15px 0px 0px;
                        position: sticky;
                        bottom: 0;
                        width: 540px;
                        z-index: 1;">
                  <v-card-text
                    class="pa-4">
                    <div
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Pilih Penugasan
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.single_partner = true; selected.single_partner = form.single_partner;"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="form.single_partner === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold one-line ml-1"
                              style="max-width: 150px;">
                              Penugasan {{ form.single_partner }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      :class="form.type === 'home_care' ? 'my-3' : 'mt-3 mb-4'"
                      style="border-top: 1px dashed #bbb;" />


                    <!-- catatan khusus admin -->
                    <div
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Catatan Khusus Admin
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.note_khusus = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="form.note_khusus === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold one-line ml-1"
                              style="max-width: 150px;">
                              {{ form.note_khusus }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      :class="form.type === 'home_care' ? 'my-3' : 'mt-3 mb-4'"
                      style="border-top: 1px dashed #bbb;" />

                    <!-- alamat -->
                    <div v-if="form.type === 'home_care'"
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Alamat
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.address = true; fetchAddress();"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="form.customer_address_id === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold one-line ml-1"
                              style="width: 150px;">
                              {{ selected.address.address_full }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider v-if="form.type === 'home_care'"
                      class="my-3"
                      style="border-top: 1px dashed #bbb;" />

                    <!-- transport -->
                    <div v-if="form.type === 'home_care'"
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Transport
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.transport = true; fetchTransport();"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="form.transport_cost < 1">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ Number(form.transport_total_cost) | price }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider v-if="form.type === 'home_care'"
                      class="mt-3 mb-4"
                      style="border-top: 1px dashed #bbb;" />

                    <!-- simpan -->
                    <div
                      class="d-flex align-center">
                      <div
                        class="d-flex align-center">
                        <div
                          class="mr-2">
                          <v-img
                            @click="delete_schedule()"
                            contain
                            width="30"
                            height="30"
                            alt="bidanvitacare"
                            :src="require('@/assets/icon/trash.png')"
                            class="ma-auto cursor-pointer">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div>
                          <v-btn
                            @click="save_schedule()"
                            :disabled="process.form"
                            :loading="process.form"

                            block
                            large
                            rounded
                            elevation="0"
                            class="body-1 font-weight-bold white--text text-capitalize btn_save">
                            <div
                              class="btn_save_in d-flex align-center justify-center">
                              <span
                                class="mr-2">
                                Simpan
                              </span>

                              <v-icon color="white">mdi-check-circle</v-icon>
                            </div>
                          </v-btn>
                        </div>
                      </div>

                      <v-spacer />

                      <div>
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          <span
                            @click="dialog.saldo = true; reset_dp();"
                            class="d-flex align-center cursor-pointer">
                            <v-icon color="#666666" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="ml-1">
                              Down Payment
                            </span>
                          </span>
                        </div>

                        <div
                          class="body-1 font-weight-bold black--text">
                          {{ form.dp | price }}
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false,

          pendamping: false,
          klien: false,
          klien_data: false,
          klien_data_edit: false,

          address: false,

          saldo: false,

          note_khusus: false,
          single_partner: false,
          transport: false,

          check_available: false
        },

        sheet: {
          pelanggan: false,
          form_pelanggan: false,

          item: false,

          error: false
        },

        selected: {
          pelanggan: {},
          item: 'service',

          item_index_to_visit: '',

          visit_klien_index: '',
          visit_klien_object: {},

          address: {},
          type_item: 'service',
          single_partner: ''
        },

        config: {
          type: [
            {
              text: 'On Site',
              value: 'on_site'
            },
            {
              text: 'Home Care',
              value: 'home_care'
            },
            // {
            //   text: 'Produk',
            //   value: 'product'
            // }
          ],

          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.'
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.'
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.'
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.'
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran melalui paylater berjangka dari Shopeepay.'
            }
          ],
          bank: [
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            },
          ],

          type_pelanggan: [
            {
              text: 'Ibu Hamil',
              value: 'ibu_hamil'
            },
            {
              text: 'Ibu Menyusui',
              value: 'ibu_menyusui'
            },
            {
              text: 'Bayi',
              value: 'bayi'
            },
            {
              text: 'Anak',
              value: 'anak'
            },
            {
              text: 'Umum',
              value: 'umum'
            }
          ],
          type_item: [
            {
              text: 'Layanan',
              value: 'service'
            },
            {
              text: 'Produk',
              value: 'product'
            },
            // {
            //   text: 'Bahan',
            //   value: 'material'
            // }
          ]
        },

        search_pelanggan: '',
        list_pelanggan: [],
        pagination_pelanggan: {},
        limit_pelanggan: 10,

        search_item: '',
        list_item: [],
        pagination_item: {},
        limit_item: 15,

        search_klien: '',
        list_klien: [],
        pagination_klien: {},

        search_terapis: '',
        list_terapis: [],
        pagination_terapis: {},

        search_address: '',
        list_address: [],
        pagination_address: {},

        search_transport: '',
        list_transport: [],
        pagination_transport: {},

        list_shift: [],

        form: {
          type: 'on_site',
          customer_id: '',
          customer_name: '',
          customer_address_id: '',
          note: '',
          note_khusus: '',

          dp: 0,
          dp_payment_method: '',
          dp_payment_method_bank_name: '',
          dp_payment_method_ref: '',

          wali: '',
          mobilephone_wali: '',
          mobilephone_other: '',

          transport_id: '',
          transport_name: '',
          transport_cost: 0,
          // tambahan
          transport_qty: 1,
          transport_total_cost: 0,

          single_partner: '',

          details: []
        },

        form_pelanggan: {
          id: '',
          name: '',
          mobilephone: '',
          type: 'umum',
          hpht_date: ''
        },

        form_visit: {
          datetime: '',
          toleransi_datetime: '',

          client_id: 0,
          client_name: '',
          client_image_url: '',

          therapist_id: 0,
          therapist_name: '',
          therapist_image_url: '',
          therapist_shift: '',

          date: '',
          time: '',

          toleransi_time: ''
        },

        check_datetime_available: [],

        picker: {
          hpht_date: false,
          visit_date: false,
          visit_time: false,
          visit_toleransi_time: false,
          schedule_date: false,
          schedule_time: false
        },

        process: {
          load: false,
          limit: false,

          form: false,

          klien: false,
          terapis: false,

          load_item: false,
          limit_item: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        },

        // SEO
        content: {
          url: '/jadwal/new/desktop/tambah',
          title: 'Tambah Jadwal Desktop',
          description: 'Tambah Jadwal Desktop Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'form.type': function (val) {
        if (val === 'product') {
          this.form.details = []

          this.config.type_item = [
            {
              text: 'Produk',
              value: 'product'
            }
          ]

          this.selected.item = 'product'
        } else {
          this.config.type_item = [
            {
              text: 'Layanan',
              value: 'service'
            },
            {
              text: 'Produk',
              value: 'product'
            },
            {
              text: 'Bahan',
              value: 'material'
            }
          ]

          this.selected.item = 'service'
        }
      },

      'limit_pelanggan': function() {
        this.process.limit = true

        this.fetchPelanggan('limit')
      },

      'limit_item': function() {
        this.process.limit_item = true

        this.fetchItem('limit')
      },

      'search_klien': function() {
        this.fetchKlien()
      },

      'form_visit.client_id': function() {
        let self = this
        let data = this.list_klien.filter(function(v) {
          return self.form_visit.client_id == v.id
        })

        if (data.length > 0) {
          self.form_visit.client_name = data[0].name
          self.form_visit.client_image_url = data[0].image_url
        }

        this.fetchTerapis()
        this.fetchShift()
      },

      'search_terapis': function() {
        this.fetchTerapis()
      },

      'form_visit.therapist_id': function() {
        let self = this
        let data = this.list_terapis.filter(function(v) {
          return self.form_visit.therapist_id == v.id
        })

        if (data.length > 0) {
          self.form_visit.therapist_name = data[0].name
          self.form_visit.therapist_image_url = data[0].image_url
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.pop_state()

      this.fetchItem()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetchPelanggan (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_pelanggan,
          limit: type !== undefined ? this.limit_pelanggan : 10
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            let data = response.results.data

            this.list_pelanggan = []

            data.map(obj => {
              this.list_pelanggan.push({
                id: obj.id,
                name: obj.name,
                image_url: obj.image_url,
                mobilephone: obj.mobilephone,
                role: obj.role,
                age: obj.age,

                picker: {
                  visit_date: false,
                  visit_time: false,

                  visit_toleransi_time: false
                }
              })
            })

            this.pagination_pelanggan = response.results.pagination
          }
        })
      },

      // ubah klien dengan data dari pelanggan
      async fetchKlien () {
        this.process.klien = true

        let params = {
          search: this.search_klien,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.klien = false

          if (response.status === 200) {
            this.list_klien = response.results.data
            this.pagination_klien = response.results.pagination
          }
        })
      },

      async fetchItem (type) {
        this.process.load_item = type === undefined ? true : false

        let params = {
          search: this.search_item,
          'type[eq]': this.selected.item,
          limit: type !== undefined ? this.limit_item : 15
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.load_item = false

          this.process.limit_item = false

          if (response.status === 200) {
            this.list_item = response.results.data
            this.pagination_item = response.results.pagination
          }
        })
      },

      async fetchTerapis () {
        this.process.terapis = true

        let params = {
          search: this.search_terapis,
          limit: 10000,

          customer_id: this.form_visit.client_id
        }

        await this.$axios.$get(`${process.env.API}admin/user/therapist-allow`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.list_terapis = response.results.data
            this.pagination_terapis = response.results.pagination
          }
        })
      },

      async fetchAddress () {
        this.process.load = true

        let client_ids = []

        this.form.details.forEach((item, index) => {
          item.visit.forEach((item_visit, index_visit) => {
            client_ids.push(item_visit.client_id)
          })
        })

        client_ids.push(this.form.customer_id)

        let params = {
          search: this.search_address,
          'customer_id[in]': [...new Set(client_ids)].toString(),
          limit: 10000,

          // id: id !== undefined ? id : ''
        }

        await this.$axios.$get(`${process.env.API}admin/customer-address`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list_address = response.results.data
            this.pagination_address = response.results.pagination
          }
        })
      },

      async fetchTransport () {
        this.process.load = true

        let params = {
          search: this.search_transport,
          'type[eq]': 'transport',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list_transport = response.results.data
            this.pagination_transport = response.results.pagination
          }
        })
      },

      async fetchShift () {
        await this.$axios.$get(`${process.env.API}admin/shift`)
        .then((response) => {
          if (response.status === 200) {
            this.list_shift = response.results.data
          }
        })
      },

      async fetchShiftInput () {
        let params = {
          user_id: this.form_visit.therapist_id,
          date: this.form.details[this.selected.item_index_to_visit].type === 'product' ? this.form.details[0].visit[0].datetime.split(' ')[0] : this.form_visit.date
        }
        await this.$axios.$get(`${process.env.API}admin/user_shift/data`, { params })
        .then((response) => {
          if (response.status === 200) {
            if (response.results.data.length > 0) {
              this.form_visit.therapist_shift = response.results.data[0].shift_name
            } else {
              this.form_visit.therapist_shift = ''
              alert(
                "Terapis belum mempunyai jadwal shift " +"\n"
                +"Anda masih dapat memilih jadwal shift di list Shift");
              }
          }
        })
      },

      // tambah pelanggan
      reset_pelanggan (item) {
        if (this.$refs.form_pelanggan) this.$refs.form_pelanggan.reset()

        this.form_pelanggan = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          mobilephone: item !== undefined ? item.mobilephone : '',
          type: item !== undefined ? item.type : 'umum',
          hpht_date: item !== undefined ? item.hpht_date : ''
        }

        this.picker.hpht_date = false

        this.process.form = false

        this.message.error = ''
      },

      async save_pelanggan () {
        this.message.error = ''

        const isValid = await this.$refs.form_pelanggan.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_pelanggan.id === '' ? 'admin/customer/add' : 'admin/customer/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form_pelanggan)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.form_pelanggan = false

              this.sheet.pelanggan = true

              this.fetchPelanggan()
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_pelanggan.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_pelanggan.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      // tambah item
      addItem (item) {
        this.form.details.push({
          item_id: item.id,
          item_name: item.name,
          item_image_url: item.image_url,
          item_duration_minutes: item.duration_minutes,

          qty: 1,
          type: item.type,
          note: '',
          active: false,

          visit: []
        })

        this.sheet.item = false
      },

      qtyMin (index) {
        this.form.details[index].qty -= 1
      },
      qtyPlus (index) {
        this.form.details[index].qty += 1
      },

      // tambah klien
      addKlien (item, index) {
        this.form.details[this.selected.item_index_to_visit].visit.push({
          datetime: this.form.details[this.selected.item_index_to_visit].type === 'product' ? this.form.details[0].visit.length > 0 ? this.form.details[0].visit[0].datetime : '' : this.form_visit.date && this.form_visit.time ? `${this.form_visit.date} ${this.form_visit.time}` : '',
          toleransi_datetime: this.form.details[this.selected.item_index_to_visit].type === 'product' ? this.form.details[0].visit.length > 0 ? this.form.details[0].visit[0].datetime : '' : this.form_visit.date && this.form_visit.time ? `${this.form_visit.date} ${this.form_visit.time}` : '',

          client_id: item.id ? item.id : 0,
          client_name: item.name,
          client_image_url: item.image_url,

          therapist_id: this.form_visit.therapist_id ? this.form_visit.therapist_id : 0,
          therapist_name: this.form_visit.therapist_name,
          therapist_image_url: this.form_visit.therapist_image_url,
          therapist_shift: this.form_visit.therapist_shift
        })

        this.selected.visit_klien_index = ''
        this.selected.visit_klien_object = {}

        this.form_visit = {
          datetime: '',
          toleransi_datetime: '',

          client_id: 0,
          client_name: '',
          client_image_url: '',

          therapist_id: 0,
          therapist_name: '',
          therapist_image_url: '',
          therapist_shift: '',

          date: '',
          time: '',

          toleransi_time: ''
        }

        this.search_pelanggan = ''
      },

      toEditKlien (item, index) {
        this.selected.visit_klien_index = index
        this.selected.visit_klien_object = item

        this.form_visit = {
          datetime: item.datetime !== '' && item.datetime !== null ? item.datetime : '',
          toleransi_datetime: item.toleransi_datetime !== '' && item.toleransi_datetime !== null ? item.toleransi_datetime : '',

          client_id: item.client_id ? item.client_id : 0,
          client_name: item.client_name,
          client_image_url: item.client_image_url,

          therapist_id: item.therapist_id ? item.therapist_id : 0,
          therapist_name: item.therapist_name,
          therapist_image_url: item.therapist_image_url,
          therapist_shift: item.therapist_shift,

          date: item.datetime !== '' && item.datetime !== null ? item.datetime.split(' ')[0] : '',
          time: item.datetime !== '' && item.datetime !== null ? item.datetime.split(' ')[1] : '',

          toleransi_time: item.toleransi_datetime !== '' && item.toleransi_datetime !== null ? item.toleransi_datetime.split(' ')[1] : ''
        }

        this.dialog.klien = false
        this.dialog.klien_data_edit = true
      },

      editKlien () {
        this.form.details[this.selected.item_index_to_visit].visit[this.selected.visit_klien_index] = {
          datetime: this.form_visit.date && this.form_visit.time ? `${this.form_visit.date} ${this.form_visit.time}` : '',
          toleransi_datetime: this.form_visit.date && this.form_visit.time ? `${this.form_visit.date} ${this.form_visit.time}` : '',

          client_id: this.form_visit.client_id ? this.form_visit.client_id : 0,
          client_name: this.form_visit.client_name,
          client_image_url: this.form_visit.client_image_url,

          therapist_id: this.form_visit.therapist_id ? this.form_visit.therapist_id : 0,
          therapist_name: this.form_visit.therapist_name,
          therapist_image_url: this.form_visit.therapist_image_url,
          therapist_shift: this.form_visit.therapist_shift
        }

        this.selected.visit_klien_index = ''
        this.selected.visit_klien_object = {}

        this.form_visit = {
          datetime: '',
          toleransi_datetime: '',

          client_id: 0,
          client_name: '',
          client_image_url: '',

          therapist_id: 0,
          therapist_name: '',
          therapist_image_url: '',
          therapist_shift: '',

          date: '',
          time: '',

          toleransi_time: ''
        }
      },

      // tambah alamat
      addAddress (item) {
        this.form.customer_address_id = item.id

        this.selected.address = item
      },

      delete_schedule () {
        this.form = {
          type: 'on_site',
          customer_id: '',
          customer_name: '',
          customer_address_id: '',
          note: '',

          dp: 0,
          dp_payment_method: '',
          dp_payment_method_bank_name: '',
          dp_payment_method_ref: '',

          wali: '',
          mobilephone_wali: '',
          mobilephone_other: '',

          details: []
        }
      },

      // tambah dp
      reset_dp () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form.dp = 0
        this.form.dp_payment_method = ''
        this.form.dp_payment_method_bank_name = ''
        this.form.dp_payment_method_ref = ''
      },

      async save_dp () {
        const isValid = await this.$refs.form.validate()
        if (isValid) {

          if (this.form.dp === '') this.form.dp = 0

          this.dialog.saldo = false

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      checkVisit () {
        let is_valid = true

        for (let i = 0; i < this.form.details.length; i++) {
          if (this.form.details[i].visit.length < 1) {
            is_valid = false

            break
          }
        }

        return is_valid
      },

      checkItem (id) {
        let is_exist = false

        for (let i = 0; i < this.form.details.length; i++) {
          if (this.form.details[i].item_id === id) {
            is_exist = true

            break
          }
        }

        return is_exist
      },

      async save_schedule () {
        this.message.errors = []

        if (this.form.customer_id === '') {
          this.message.errors.push('Silahkan pilih <strong>Pelanggan</strong>')
        }

        if (this.form.single_partner === '') {
          this.message.errors.push('Silahkan pilih <strong>Penugasan</strong>')
        }

        if (!this.checkVisit()) {
          this.message.errors.push('Silahkan periksa <strong>Klien</strong> disetiap layanan')
        }

        if (this.form.type === 'home_care' && (this.form.customer_address_id === '' || this.form.customer_address_id === undefined)) {
          this.message.errors.push('Silahkan tambahkan <strong>Alamat</strong>')
        }

        if (this.message.errors.length > 0) {
          this.sheet.error = true
        } else {
          this.message.error = ''

          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/schedule_v3/add`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.$router.push({ path: '/jadwal/new/desktop' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        }
      },

      // transport
      addTransport (item) {
        this.form.transport_id = item.id
        this.form.transport_name = item.name
        this.form.transport_cost = item.retail_price

        this.qtyTransportUpdate()
      },

      qtyTransportMin () {
        this.form.transport_qty -= 1

        this.qtyTransportUpdate()
      },
      qtyTransportPlus () {
        this.form.transport_qty += 1

        this.qtyTransportUpdate()
      },

      qtyTransportUpdate () {
        this.form.transport_total_cost = this.form.transport_cost * this.form.transport_qty
      },

      // cek datetime available
      async checkDatetimeAvailable () {
        let datetime = `${this.form_visit.date} ${this.form_visit.time}`,
            toleransi_datetime = `${this.form_visit.date} ${this.form_visit.toleransi_time}`

        await this.$axios.$post(`${process.env.API}admin/schedule_v3/check_datetime/available`, {
          id: this.selected.visit_klien_object.id,
          therapist_id: this.form_visit.therapist_id,
          start_datetime: this.form_visit.toleransi_time === '' || this.form_visit.toleransi_time === null ? datetime : toleransi_datetime,
          end_datetime: this.toMomentDuration(datetime)
        })
        .then((response) => {
          if (response.status === 200) {
            this.check_datetime_available = response.results.data

            this.dialog.check_available = this.check_datetime_available.length > 0 ? true : false
          }
        })
      },

      toMomentDuration (datetime) {
        let minutes = Number(this.form.details[this.selected.item_index_to_visit].item_duration_minutes) * Number(this.form.details[this.selected.item_index_to_visit].qty)

        return moment(datetime).add(minutes, 'minutes').format('YYYY-MM-DD HH:mm')
      }
    }
  }
</script>

<style>
  .item_type .v-input__slot {
    padding: 0 16px !important;
    width: 150px !important;
    min-height: 30px !important;
    height: 30px !important;
    font-size: 0.8rem !important;
    padding-right: 5px !important;
  }

  .item_type .v-input__append-inner {
    margin: 4px !important;
  }

  .input_qty .v-input__slot {
    padding: 0 16px !important;
    width: 75px !important;
    min-height: 28px !important;
  }
</style>
